.theme-btn {
  width: 40px;
  height: 40px;
  color: #fff;
  background: var(--themeColor);
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0.6;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  &:hover {
    opacity: 1;
    > .theme-color {
      opacity: 1;
      height: 250px;
    }
  }
  .theme-color {
    position: absolute;
    left: 5px;
    height: 0;
    overflow: hidden;
    border-radius: 0 0 20px 20px;
    opacity: 0;
    bottom: 40px;
    padding-bottom: 10px;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    .color {
      display: block;
      width: 30px;
      height: 30px;
      cursor: pointer;
      opacity: 0.6;
      margin-top: 5px;
      border-radius: 50%;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      &:hover {
        opacity: 1;
      }
    }
  }
}
