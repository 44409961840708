.warp {
  margin-top: 48px;
  min-height: calc(100vh - 256px);
  .chapters {
    margin: 20px 0 80px;
    padding: 0;
    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      flex-direction: column;
      .chapter {
        font-weight: bold;
      }
      .paragraphs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        max-width: 90%;
        .paragraph {
          margin-top: 20px;
          letter-spacing: 0.3em;
          line-height: 2em;
          text-align: left;
          text-indent: 2em;
        }
      }
      &:after {
        content: "";
        display: block;
        margin: 40px auto 80px;
        width: 10%;
        height: 2px;
        background: var(--themeColor);
        text-align: center;
      }
      &:last-child:after {
        content: "";
        margin: 60px auto;
        width: 0;
        height: 0;
        display: none;
        text-align: center;
      }
    }
  }
}
